module.exports = {
  title: '车学堂后台管理系统',

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: false,

  uploadImageUrl: 'https://img3.drivedu.com.cn/',

  tikuImageUrl: 'https://img3.drivedu.com.cn/'
}
