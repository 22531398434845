import request from '@/utils/request'

const VERSION = process.env.VUE_APP_VERSION

export function orderpractice(data) {
  return request({
    url: VERSION + '/tiku/sequence',
    method: 'get',
    params: data
  })
}
export function questioninfo(data) {
  return request({
    url: VERSION + '/tiku/question',
    method: 'get',
    params: data
  })
}
export function addcollect(data) {
  return request({
    url: VERSION + '/tiku/addfavor',
    method: 'post',
    params: data
  })
}
export function addwrong(data) {
  return request({
    url: VERSION + '/tiku/adderror',
    method: 'post',
    params: data
  })
}
export function getexam(data) {
  return request({
    url: VERSION + '/tiku/exam',
    method: 'get',
    params: data
  })
}
export function saverecord(data) {
  return request({
    url: VERSION + '/tiku/saverecord',
    method: 'post',
    params: data
  })
}
export function getrecord(data) {
  return request({
    url: VERSION + '/tiku/getrecord',
    method: 'get',
    params: data
  })
}
export function examsubmit(data) {
  return request({
    url: VERSION + '/exam/submit',
    method: 'post',
    params: data
  })
}
export function gettranscripts(data) {
  return request({
    url: VERSION + '/tiku/gettranscripts',
    method: 'get',
    params: data
  })
}
