<template>
  <div class="home">
    <div class="banner"><img src="../assets/images/banner1.jpg" alt="" /></div>
    <div class="current-position">
      <el-breadcrumb>
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span class="icon-home"></span>首页</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/exam' }">考试</el-breadcrumb-item>
        <el-breadcrumb-item>{{ licence_name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="subject-box" v-if="questionIndex < 0" v-loading="true"></div>
    <div class="subject-box" v-if="questionIndex >= 0">
      <div class="topic-info">
        <div class="topic">
          <p>
            <el-tag type="success">{{ this.question.question_type }}</el-tag>
            {{ questionIndex + 1 }}/{{ this.total }}、{{ question.title }}
            <span style="color:#aaa;font-size:12px;">
              ({{ question.chapter_id }}.{{ question.chapter_name }})
            </span>
          </p>
          <el-button
            size="medium"
            icon="el-icon-star-off"
            @click="collect(question.question_id, questionIndex)"
          >
            收藏
          </el-button>
        </div>
        <div class="answer">
          <div class="answer-item" v-if="this.question.question_type == '多选'">
            <el-checkbox-group v-model="checkedCities">
              <p v-for="item in question.items" :key="item.sign">
                <span v-show="showAnswer">
                  <span
                    class="icon-cancel"
                    v-if="
                      question.answer
                        .toString()
                        .split(',')
                        .includes(characterData[item.sign - 1]) == false
                    "
                  >
                  </span>
                  <span
                    class="icon-checkmark1"
                    v-if="
                      question.answer
                        .toString()
                        .split(',')
                        .includes(characterData[item.sign - 1]) == true
                    "
                  >
                  </span>
                </span>
                <!--只保留正确样式-->
                <span v-show="showAnswer2">
                  <span
                    class="icon-checkmark1"
                    v-if="
                      question.answer
                        .toString()
                        .split(',')
                        .includes(characterData[item.sign - 1]) == true
                    "
                  >
                  </span>
                  <span
                    style="margin-left:16px"
                    v-if="
                      question.answer
                        .toString()
                        .split(',')
                        .includes(characterData[item.sign - 1]) == false
                    "
                  >
                  </span>
                </span>
                <el-checkbox :label="characterData[item.sign - 1]">
                  {{ characterData[item.sign - 1] }}：{{ item.item_title }}
                </el-checkbox>
              </p>
            </el-checkbox-group>
            <el-button type="primary" @click="checkBoxSubmit()">
              确认答案
            </el-button>
          </div>
          <div class="answer-item" v-if="this.question.question_type != '多选'">
            <p
              style="cursor:pointer"
              v-for="item in question.items"
              :key="item.sign"
              @click="
                selectAnswer(characterData[item.sign - 1], question.question_id)
              "
            >
              <span v-show="showAnswer">
                <span
                  class="icon-cancel"
                  v-if="characterData[item.sign - 1] != question.answer"
                >
                </span>
                <span
                  class="icon-checkmark1"
                  v-if="characterData[item.sign - 1] == question.answer"
                >
                </span>
              </span>
              <!--只保留正确样式-->
              <span v-show="showAnswer2">
                <span
                  class="icon-checkmark1"
                  v-if="characterData[item.sign - 1] == question.answer"
                >
                </span>
                <span
                  style="margin-left:21px"
                  v-if="characterData[item.sign - 1] != question.answer"
                >
                </span>
              </span>
              {{ characterData[item.sign - 1] }}：{{ item.item_title }}
            </p>
          </div>
          <div class="media">
            <el-row v-if="question.image_url" class="thumb">
              <el-col :offset="1" :span="20">
                <a :href="tikuImageUrl + question.image_url" target="_blank">
                  <img
                    v-bind:src="tikuImageUrl + question.image_url"
                    alt=""
                    height="200px"
                  />
                </a>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div class="questions-info">
        <p class="type">
          {{ this.question.question_type }}，请选择您认为正确的答案！
        </p>
        <div class="btn-bar">
          <el-button
            type="primary"
            v-if="questionIndex == 0"
            disabled
            @click="back()"
          >
            上一题
          </el-button>
          <el-button
            type="primary"
            v-else
            :disabled="this.back_use"
            @click="back()"
          >
            上一题
          </el-button>
          <el-button
            type="primary"
            v-if="questionIndex == this.total - 1"
            disabled
            @click="next()"
          >
            下一题
          </el-button>
          <el-button
            type="primary"
            v-else
            :disabled="this.next_use"
            @click="next()"
          >
            下一题
          </el-button>
          <!-- <el-checkbox style="margin-left:24px" v-model="checked">答对自动下一题</el-checkbox> -->
          <div class="msg-bar">
            <span>答对：{{ this.rightNum }}题</span>
            <span>答错：{{ this.wrongNum }}题</span>
          </div>
          <el-button
            type=""
            size="medium"
            class="right"
            @click="showDescription = !showDescription"
            >查看详解</el-button
          >
          <el-button
            type=""
            size="medium"
            class="right"
            @click="keepRecords(questionIndex)"
          >
            保存记录
          </el-button>
        </div>
        <div class="break-down-box" v-show="showDescription">
          <h2><span>试题详解</span></h2>
          <p>正确答案：{{ question.answer }}</p>
          <p>
            难度：
            <el-rate
              v-model="question.difficulty"
              disabled
              show-text
              :texts="texts"
            ></el-rate>
          </p>
          <p>知识点：{{ question.skill }}</p>
          <p>试题解析：{{ question.analysis }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  orderpractice,
  questioninfo,
  addcollect,
  addwrong,
  saverecord,
  getrecord
} from '../api/tiku'
import { getSubject } from '../api/courses'
import {
  getLxCorrectAnswer,
  getLxIsSelect,
  getLxQuestionId,
  getLxWrongAnswer,
  getResult,
  setLxCorrectAnswer,
  setLxIsSelect,
  setLxLicenceId,
  setLxQuestionId,
  setLxSubjectId,
  setLxWrongAnswer,
  removeLxIsSelect,
  removeLxWrongAnswer,
  removeLxCorrectAnswer,
  removeLxQuestionId,
  setLxcollectQuestionId
} from '../utils/auth'
import { tikuImageUrl } from '@/settings'
export default {
  name: 'Home',
  data() {
    return {
      licence_name: '',
      rightNum: 0,
      wrongNum: 0,
      checked: true,
      tikuImageUrl,
      showDescription: false,
      question: {
        question_id: 0,
        title: '',
        items: [],
        image_url: '',
        answer: '',
        difficulty: 0,
        analysis: '',
        skill: '',
        chapter_id: '',
        chapter_name: '',
        licence_id: 2,
        subject_id: 1,
        is_vip: false,
        is_exam: true
      },
      questionList: [],
      questionIndex: -1,
      characterData: ['A', 'B', 'C', 'D', 'E'],
      texts: ['简单', '较易', '一般', '较难', '困难'],
      total: 0,
      showAnswer: false,
      showAnswer2: false,
      isSelect: [],
      user_id: '',
      back_use: false,
      next_use: false,
      wrongAnswer: [],
      correctAnswer: [],
      checkedCities: [],
      is_true: false
    }
  },
  created() {
    this.licence_id = this.$route.query.licence_id
    this.subject_id = this.$route.query.subject_id
    this.licence_name = this.$route.query.licence_name
    if (getResult() === undefined) {
      this.user_id = ''
    } else {
      this.user_id = JSON.parse(getResult()).userid
    }
    if (this.subject_id == '') {
      getSubject({ licence_id: this.licence_id }).then(res => {
        if (res.code === 0) {
          this.subject_id = res.result.subject_list[0].subject_id
          this.getList(this.licence_id, this.subject_id)
        }
      })
    } else {
      this.getList(this.licence_id, this.subject_id)
    }
  },
  methods: {
    //上一题
    back() {
      this.checkedCities = []
      if (this.questionIndex > 0) {
        this.next_use = false
        this.questionIndex--
      } else {
        this.back_use = true
      }
    },
    //下一题
    next() {
      this.checkedCities = []
      if (
        this.questionIndex >= 0 &&
        this.questionIndex < this.questionList.length - 1
      ) {
        this.back_use = false
        this.questionIndex++
      } else {
        this.next_use = true
      }
    },
    //获取列表
    getList(licence_id, subject_id) {
      let params = {
        licence_id: licence_id,
        subject_id: subject_id
      }
      orderpractice(params).then(res => {
        if (res.code == 0) {
          this.questionList = res.data.list
          this.total = res.data.total
          if (getResult() === undefined) {
            this.questionIndex = 0
          } else {
            let params = {
              user_id: this.user_id,
              licence_id: this.licence_id,
              subject_id: this.subject_id,
              record_type: 1
            }
            getrecord(params).then(res => {
              if (res.code == 0) {
                this.questionIndex = res.data.record_no - 1
              }
            })
          }
        } else {
          this.questionIndex = -1
        }
      })
    },
    //选择答案
    selectAnswer(answer) {
      this.isSelect =
        getLxIsSelect() !== undefined ? JSON.parse(getLxIsSelect()) : []
      // 答过的题ID加入数组中
      if (this.isSelect.indexOf(this.questionList[this.questionIndex]) == -1) {
        this.isSelect.push(this.questionList[this.questionIndex])
      } else {
        return
      }
      setLxIsSelect(this.isSelect)
      var is_true = false
      if (answer == this.question.answer) {
        is_true = true
      } else {
        is_true = false
      }
      this.judge(is_true)
    },
    //回答正确错误处理
    judge(is_true) {
      if (is_true) {
        // 是否勾选答对下一题
        if (this.checked == true) {
          //答对push到correctAnswer数组
          this.correctAnswer =
            getLxCorrectAnswer() !== undefined
              ? JSON.parse(getLxCorrectAnswer())
              : []
          if (
            this.correctAnswer.indexOf(this.questionList[this.questionIndex]) ==
            -1
          ) {
            this.correctAnswer.push(this.questionList[this.questionIndex])
            setLxCorrectAnswer(this.correctAnswer)
          }
          this.rightNum++
          this.showAnswer = false
          this.showAnswer2 = true
          this.next()
        } else {
          this.showDescription = true
        }
      } else {
        // 回答错误
        //答错push到wrongAnswer数组
        this.wrongAnswer =
          getLxWrongAnswer() !== undefined ? JSON.parse(getLxWrongAnswer()) : []
        if (
          this.wrongAnswer.indexOf(this.questionList[this.questionIndex]) == -1
        ) {
          this.wrongAnswer.push(this.questionList[this.questionIndex])
          setLxWrongAnswer(this.wrongAnswer)
        }
        this.wrongNum++
        this.showAnswer = true
        this.showAnswer2 = false
        this.showDescription = true
        if (getResult() != undefined) {
          let params = {
            user_id: this.user_id,
            question_id: this.question.question_id
          }
          addwrong(params).then(res => {
            // console.log(res)
          })
        }
      }
    },
    //添加到收藏
    collect(question_id, questionIndex) {
      if (this.user_id == '') {
        this.$confirm('请您登录后再进行收藏', '提示', {
          confirmButtonText: '我知道了',
          type: 'warning'
        }).then(() => {
          setLxLicenceId(this.licence_id)
          setLxSubjectId(this.subject_id)
          setLxQuestionId(questionIndex)
          location.href = '#/login'
        })
      } else {
        let params = {
          user_id: this.user_id,
          question_id: question_id
        }
        addcollect(params).then(res => {
          if (res.code == 0) {
            this.$message.success('收藏成功')
          } else {
            this.$message.error(res.message)
            return
          }
        })
      }
    },
    keepRecords(questionIndex) {
      if (this.user_id == '') {
        this.$confirm('请您登录后再进行保存记录', '提示', {
          confirmButtonText: '我知道了',
          type: 'warning'
        }).then(() => {
          setLxLicenceId(this.licence_id)
          setLxSubjectId(this.subject_id)
          setLxIsSelect(this.isSelect)
          setLxWrongAnswer(this.wrongAnswer)
          setLxCorrectAnswer(this.correctAnswer)
          setLxQuestionId(questionIndex)
          location.href = '#/login'
        })
      } else {
        let params = {
          user_id: this.user_id,
          licence_id: this.licence_id,
          subject_id: this.subject_id,
          record_type: 1,
          record_no: questionIndex + 1
        }
        saverecord(params).then(res => {
          if (res.code == 0) {
            this.$message.success('记录成功')
          } else {
            this.$message.error(res.message)
            return
          }
        })
      }
    },
    checkBoxSubmit() {
      this.isSelect =
        getLxIsSelect() !== undefined ? JSON.parse(getLxIsSelect()) : []
      if (this.isSelect.indexOf(this.questionList[this.questionIndex]) == -1) {
        this.isSelect.push(this.questionList[this.questionIndex])
      } else {
        return
      }
      setLxIsSelect(this.isSelect)
      let answerArr = []
      var is_true = false
      answerArr = this.question.answer.toString().split(',')
      if (this.checkedCities.sort().toString() == answerArr.sort().toString()) {
        is_true = true
      } else {
        is_true = false
      }
      this.judge(is_true)
    }
  },
  watch: {
    questionIndex: function() {
      if (getLxQuestionId() !== undefined) {
        this.questionIndex = Number(getLxQuestionId())
        removeLxQuestionId()
      }
      this.isSelect =
        getLxIsSelect() !== undefined ? JSON.parse(getLxIsSelect()) : []
      this.wrongAnswer =
        getLxWrongAnswer() !== undefined ? JSON.parse(getLxWrongAnswer()) : []
      this.correctAnswer =
        getLxCorrectAnswer() !== undefined
          ? JSON.parse(getLxCorrectAnswer())
          : []
      if (this.questionIndex >= 0) {
        questioninfo({ id: this.questionList[this.questionIndex] }).then(
          response => {
            if (response.code === 0) {
              let question = response.data
              let answerLabel = []
              question.answer
                .toString()
                .split(',')
                .forEach(function(item) {
                  answerLabel.push(String.fromCharCode(64 + parseInt(item)))
                })
              let typeName = ''
              if (question.question_type == 1) {
                typeName = '单选'
              }
              if (question.question_type == 2) {
                typeName = '多选'
              }
              if (question.question_type == 3) {
                typeName = '判断'
              }
              this.question = {
                question_id: question.question_id,
                question_type: typeName,
                title: question.title,
                items: question.items,
                licence_id: question.licence_id,
                subject_id: question.subject_id,
                province_id: question.province_id,
                city_id: question.city_id,
                image_url: question.image_url,
                answer: answerLabel.join(','),
                difficulty: question.difficulty,
                analysis: question.analysis,
                skill: question.skill,
                chapter_id: question.chapter_id,
                chapter_name: question.chapter_name,
                is_vip: question.is_vip == 1 ? true : false,
                is_exam: question.is_exam == 1 ? true : false
              }
              if (
                this.isSelect.indexOf(this.questionList[this.questionIndex]) ==
                -1
              ) {
                this.showAnswer = false
                this.showAnswer2 = false
                this.showDescription = false
              } else {
                if (
                  this.wrongAnswer.indexOf(
                    this.questionList[this.questionIndex]
                  ) != -1
                ) {
                  this.showAnswer = true
                  this.showAnswer2 = false
                  this.showDescription = true
                }
                if (
                  this.correctAnswer.indexOf(
                    this.questionList[this.questionIndex]
                  ) != -1
                ) {
                  this.showAnswer = false
                  this.showAnswer2 = true
                  this.showDescription = true
                }
              }
            }
          }
        )
      }
    }
  },
  components: {}
}
</script>
